import React from "react";
import Layout from "../../components/global/layout";
import GenericHeader from "../../components/templates/genericHeader";
import GetInTouchToday from "../../components/global/GetInTouchToday";
import SEO from "../../components/global/seo";

import { graphql, StaticQuery } from "gatsby";

export default () => (
  <StaticQuery
    query={graphql`
      query CMSHeadingQuery {
        allStrapiCmspage {
          edges {
            node {
              HeaderHeading
              HeaderInfo
              MetaDescription
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        {data.allStrapiCmspage.edges.map(document => (
          <SEO
            title="Dynamics CRM 365"
            title="CMS"
            keywords={[
              `CMS`,
              "content management system",
              `digital solutions`,
              `digital marketing`
            ]}
            description={document.node.MetaDescription}
          />
        ))}

        <GenericHeader
          Heading={data.allStrapiCmspage.edges.map(document => (
            <div>{document.node.HeaderHeading}</div>
          ))}
          headerInfo={data.allStrapiCmspage.edges.map(document => (
            <div>{document.node.HeaderInfo}</div>
          ))}
        />
        <GetInTouchToday />
      </Layout>
    )}
  />
);
